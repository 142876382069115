<template>
  <v-card
    :max-width="maxWidth"
    :height="height"
  >
    <v-row
      dense
      class="grow"
    >
      <v-col
        cols="1"
        class="py-2"
      >
        <v-sheet
          color="#00796B"
          width="8"
          :height="height - 10"
          class="ml-1"
        />
      </v-col>
      <v-col
        cols="11"
      >
        <v-card-text>
          <div
            class="mt-n2 ml-n2"
            style="font-size: 9px; font-weight: bold; line-height: normal;"
          >
            General Questionnaire
          </div>
        </v-card-text>
        <v-btn
          text
          rounded
          x-small
          color="primary"
          class="mt-n1"
          style="position: absolute; bottom: 5px;"
          @click="$emit('open-general-questionairre')"
        >
          open
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiFile, mdiPencil, mdiTrashCanOutline } from '@mdi/js'

export default {
  props: {
    caseData: {
      type: Object,
      default: null
    },
    height: {
      type: String,
      default: '125'
    },
    maxWidth: {
      type: String,
      default: '125'
    }
  },
  data () {
    return {
      icons: {
        file: mdiFile,
        trash: mdiTrashCanOutline,
        edit: mdiPencil
      },
      navigation: {
        width: 450,
        borderSize: 6
      }
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    isClientAvailable () {
      let clientAccounts = []
      this.caseData.sharedWith.accounts.forEach(acc => {
        if (acc.accountType === 'company' || acc.accountType === 'individual') {
          clientAccounts.push(acc)
        }
      })
      return clientAccounts
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
